const initialState = {
    start: '',
    end: '',
    type:''
}
const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_START': {
            return {
                ...state,
                start: action.data
            }
        }
        case 'SET_END': {
            return {
                ...state,
                end: action.data
            }
        }
        case 'SET_TYPE': {
            return {
                ...state,
                type: action.data
            }
        }
        default:
            return state
    }
}
export default dashboardReducer
