const initialState = {
  typeView: 1,
  showFooter:false,
  showHeader:false,
  showAction:false
}
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_TYPE_VIEW':{
    return {
      ...state,
      typeView: action.data
    }
  }
    case 'SET_SHOW_HEADER_CUSTOMER': {
      return {
        ...state,
        showHeader: action.data
      }
    }
    case 'SET_SHOW_FOOTER_CUSTOMER': {
      return {
        ...state,
        showFooter: action.data
      }
    }
    case 'SET_SHOW_ACTION_CUSTOMER': {
      return {
        ...state,
        showAction: action.data
      }
    }
  default:
    return state
  }
}
export default customerReducer
