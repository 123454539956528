/* eslint-disable no-case-declarations */
// ** Initial State
const initialState = {
    suggestions: [],
    bookmarks: [],
    query: '',
    isProfile: false,
    routerCategories: [],
    showSidebar: false,
    menuRouterVisibility:false,
    totalApp:0,
    userApp:0
}

const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_PROFILE': {
            const newIsProfile = action.data
            return {
                ...state,
                isProfile: newIsProfile
            }
        }
        case 'SET_TOTAL_APP': {
            return {
                ...state,
                totalApp: action.data
            }
        }
        case 'SET_USER_APP': {
            return {
                ...state,
                userApp: action.data
            }
        }
        case 'SET_ROUTER_CATEGORIES': {
            const newRouterCategories = action.data
            return {
                ...state,
                routerCategories: newRouterCategories,
            }
        }
        case 'SET_SHOW_SIDEBAR': {
            return {
                ...state,
                showSidebar: action.data,
            }
        }
        case 'SET_MENU_ROUTER_VISIBILITY': {
            return {
                ...state,
                menuRouterVisibility: action.data,
            }
        }
        case 'HANDLE_SEARCH_QUERY':
            return {...state, query: action.val}
        case 'GET_BOOKMARKS':
            return {...state, suggestions: action.data, bookmarks: action.bookmarks}
        case 'UPDATE_BOOKMARKED':
            let objectToUpdate

            // ** find & update object
            // eslint-disable-next-line array-callback-return
            state.suggestions.find(item => {
                if (item.id === action.id) {
                    item.isBookmarked = !item.isBookmarked
                    objectToUpdate = item
                }
            })

            // ** Get index to add or remove bookmark from array
            const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.id)

            if (bookmarkIndex === -1) {
                state.bookmarks.push(objectToUpdate)
            } else {
                state.bookmarks.splice(bookmarkIndex, 1)
            }

            return {...state}
        default:
            return state
    }
}

export default navbarReducer
