const initialState = {
    showFilter:false,
    type:'',
    status:''
}
const serviceSupportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SHOW_FILTER_SP': {
            const newShowFilter = action.data
            return {
                ...state,
                showFilter: newShowFilter
            }
        }
        case 'SET_TYPE_SP': {
            return {
                ...state,
                type: action.data
            }
        }
        case 'SET_STATUS_SP': {
            return {
                ...state,
                status: action.data
            }
        }
        default:
            return state
    }
}
export default serviceSupportReducer
