const initialState = {
    appList: [],
    statusInstall: 0,
    appIdInstall: [],
    isInstalling: false,
    language: 'vi',
    installedApp: false,
    urlApp: '',
    countNotification: 0,
    showFilter:false,
    showFooter:false,
    showHeader:false,
    showAction:false,
    category:'',
    status:0,
    price:0,
    inputSearch:''
}
const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE': {
            return {
                ...state,
                language: action.data
            }
        }
        case 'SET_COUNT': {
            return {
                ...state,
                countNotification: action.data
            }
        }
        case 'GET_ALL': {
            return {
                ...state,
                appList: action.data
            }
        }
        case 'STATUS_INSTALL': {
            const newStatusInstall = action.data
            return {
                ...state,
                statusInstall: newStatusInstall
            }
        }
        case 'APP_ID_INSTALL': {
            const newAppIdInstall = action.data
            return {
                ...state,
                appIdInstall: newAppIdInstall
            }
        }
        case 'IS_INSTALLING': {
            const newIsInstalling = action.data
            return {
                ...state,
                isInstalling: newIsInstalling
            }
        }
        case 'SET_URL_APP': {
            const newUrlApp = action.data
            return {
                ...state,
                urlApp: newUrlApp
            }
        }
        case 'SET_INSTALLED_APP': {
            const newInstalledApp = action.data
            return {
                ...state,
                installedApp: newInstalledApp
            }
        }
        case 'SET_SHOW_FILTER': {
            const newShowFilter = action.data
            return {
                ...state,
                showFilter: newShowFilter
            }
        }
        case 'SET_SHOW_FOOTER': {
            return {
                ...state,
                showFooter: action.data
            }
        }
        case 'SET_SHOW_ACTION': {
            return {
                ...state,
                showAction: action.data
            }
        }
        case 'SET_SHOW_HEADER': {
            return {
                ...state,
                showHeader: action.data
            }
        }
        case 'SET_CATEGORY': {
            return {
                ...state,
                category: action.data
            }
        }
        case 'SET_STATUS': {
            return {
                ...state,
                status: action.data
            }
        }
        case 'SET_PRICE': {
            return {
                ...state,
                price: action.data
            }
        }
        case 'SET_INPUT_SEARCH': {
            return {
                ...state,
                inputSearch: action.data
            }
        }
        default:
            return state
    }
}
export default appReducer
