// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
// import messagesDe from '@assets/data/locales/de.json'
// import messagesFr from '@assets/data/locales/fr.json'
// import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesVi from '@src/assets/data/locales/vi.json'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux';
// import userMessagesDe from '@src/assets/data/locales/de.json'
// import userMessagesFr from '@src/assets/data/locales/fr.json'
// import userMessagesPt from '@src/assets/data/locales/pt.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  vi: { ...userMessagesVi }
  // de: { ...messagesDe, ...userMessagesDe },
  // fr: { ...messagesFr, ...userMessagesFr },
  // pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

// const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en'


const IntlProviderWrapper = ({ children }) => {
  // ** States


  if(!localStorage.getItem('language')){
    const language = useSelector(state=>state.app.language)
    localStorage.setItem('language', language)
  }
  const lang = localStorage.getItem('language')
  // console.log(lang)
  const [locale, setLocale] = useState(lang)
  const [messages, setMessages] = useState(menuMessages[lang])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }
  IntlProviderWrapper.propTypes = {
    children: PropTypes.any,
  }
  return (
    <Context.Provider value={ { locale, switchLanguage } }>
      <IntlProvider key={ locale } locale={ locale } messages={ messages } defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
