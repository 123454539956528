const initialState = {
  progress: null,
  isProgressing: false,
  isInterval: false,
  backLink: false,
  isFirst: false,
  partnerId: 0,
};
const progressReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA_PROGRESS': {
      return {
        ...state,
        progress: {
          ...action.data,
        },
      };
    }
    case 'SET_PROGRESSING': {
      return {
        ...state,
        isProgressing: action.data,
      };
    }
    case 'SET_FIRST': {
      return {
        ...state,
        isFirst: action.data,
      };
    }
    case 'SET_PARTNER_ID': {
      return {
        ...state,
        partnerId: action.data,
      };
    }
    case 'SET_IS_INTERVAL': {
      return {
        ...state,
        isInterval: action.data,
      };
    }
    case 'SET_BACK_LINK': {
      return {
        ...state,
        backLink: action.data,
      };
    }
    default:
      return state;
  }
};
export default progressReducer
