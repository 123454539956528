// **  Initial State
const initialState = {
    userData: {},
    token: null,
    isAdmin: null,
    isAM: null,
    roles_apps: null,
    roles_partner: null,
    roles_request: null,
    roles_process_request: null,
    roles_installation: null,
    roles_active_resource:null,
    roles_category_company:null,
    user: null,
    urlBack: '/',
    isUpdateIdentification:true
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return {
                ...state,
                userData: action.data,
                // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
                // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
            }
        }
        case 'USER_DATA': {
            if (action.data) {
                // console.log(action.data.user.is_lock_update && action.data.user.is_lock_update===1)
                return {
                    ...state,
                    userData: action.data,
                    token: action.data.token,
                    isAdmin: action.data.isAdmin,
                    isAM: action.data.isAM,
                    roles_apps: action.data.roles_apps,
                    roles_partner: action.data.roles_partner,
                    roles_active_resource:action.data.roles_active_resource,
                    roles_request: action.data.roles_request,
                    roles_process_request: action.data.roles_process_request,
                    roles_category_company: action.data.roles_category_company,
                    roles_installation: action.data.roles_installation,
                    user: action.data.user,
                    isUpdateIdentification:!(action.data.user.is_lock_update && action.data.user.is_lock_update === 1)
                }
            } else {
                return {
                    ...state,
                    userData: null,
                    token: null,
                    isAdmin: null,
                    roles_apps: null,
                    roles_partner: null,
                    user: null
                }
            }
        }
        case 'LOGOUT': {
            const obj = {...action}
            delete obj.type
            return {...state, userData: {}, ...obj}
        }
        case 'SET_URL_BACK': {
            return {...state, urlBack: action.data}
        }
        case 'SET_UPDATE_IDENTIFICATION': {
            return {...state, isUpdateIdentification: action.data}
        }
        default:
            return state
    }
}

export default authReducer
