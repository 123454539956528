// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import app from './app'
import progress from './progress'
import customer from './customer'
import serviceSupport from './serviceSupport'
import dashboard from './dashboard'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  app,
  progress,
  customer,
  serviceSupport,
  dashboard
})

export default rootReducer
