// ** Redux, Thunk & Root Reducer Imports
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'

// ** init middleware
// const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

// ** Create store
const store = createStore(rootReducer, composedEnhancer)


export { store }
